import React from "react";
import { logos } from "./logos-routes";
import { useTranslation } from "react-i18next";

export const SocialProof = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full py-20">
      <h2 className="text-display-md font-semibold text-darkblue700 font-brevia text-center uppercase xsm:hidden sm:hidden  md:hidden">
        {t("socialProofSection.title")}
      </h2>
      <h2 className="text-display-sm font-semibold text-darkblue700 font-brevia text-center uppercase xmd:hidden lg:hidden xl:hidden 2xl:hidden">
        {t("socialProofSection.titlemobile")}
      </h2>
      <div className="w-full flex flex-wrap gap-x-20 xmd:gap-x-20 lg:gap-x-20 gap-y-3 justify-center mt-10 lg:flex-row xl:flex-row 2xl:flex-row">
        {logos.map((logo, index) => (
          <div key={index} className="w-[35%] xmd:w-[25%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%]">
            <img src={logo.logo} alt={t("socialProofSection.logoAlt")} />
          </div>
        ))}
      </div>
    </div>
  );
};
