import React from "react";

interface Feature {
  icon: string;
  title: string;
  text: string;
  textMobile?: string;
}

interface IconListProps {
  features: Feature[];
}

export const IconList = ({ features }: IconListProps) => {
  return (
    <div className="w-full mb-10">
      <div className="w-full flex flex-col gap-y-10 px-2 mt-6 font-poppins text-md font-regular text-gray-600">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-row items-start gap-3">
            <div className="flex-none">
              <img src={feature.icon} alt="icon" className="w-11 h-11" />
            </div>
            <div className="my-2">
              <p className="text-gray900 text-lg font-semibold">{feature.title}</p>
              <p className="" >{feature.text}</p>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
