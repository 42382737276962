import React, { useState } from "react";
import hamburgerMenu from "@images/hamburger-menu.svg";
import { NavBarModalMobile } from "./navbar-modal-mobile/navbar-modal-mobile";
import restaurantsClubLogo from "@images/restaurants-club-logo.svg";
import { useTranslation } from 'react-i18next';
import ChevronDown from "@images/chevron-down.svg";
import ChevronUp from "@images/chevron-up.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";

type MenuVoice = {
  name: string;
};

type Choice = {
  lng: string;
  icon: string;
  language: string;
};

export const NavBar = () => {
  const [openNavBarModal, setOpenNavBarModal] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(italianLanguageIcon);

  const changeLanguage = (choice: Choice) => {
    i18n.changeLanguage(choice.lng);
    setCurrentLanguageIcon(choice.icon);
    setLanguageModalOpen(false);
  };

  return (
    <div className="p-8 w-full flex justify-between xmd:justify-start lg:justify-start xl:justify-start 2xl:justify-start xmd:w-[98%] lg:w-[95%] xl:w-[95%] 2xl:w-[95%] xmd:mx-auto lg:mx-auto xl:mx-auto 2xl:mx-auto relative">
      <div className="w-[30%] xsm:w-[50%] sm:w-[50%] md:w-[50%] flex flex-row">
        <img src={restaurantsClubLogo} alt="Restaurants Club Logo" />
      </div>
    {/*    <div className="flex flex-row gap-8 font-poppins text-md text-gray600 font-semibold mx-8 xsm:hidden sm:hidden md:hidden xmd:text-sm xmd:gap-2 ">
        {(t("navBarMenuVoices", { returnObjects: true }) as MenuVoice[]).map((voice, index) => (
          <button className="text-left ml-3" key={index}>
            <span>{voice.name}</span>
          </button>
        ))}
      </div>*/}
      <button onClick={() => setOpenNavBarModal(!openNavBarModal)} className="xmd:hidden lg:hidden xl:hidden 2xl:hidden">
        <div className="flex flex-row">
          <img src={hamburgerMenu} alt="Hamburger Menu" />
        </div>
      </button>
      {openNavBarModal && <NavBarModalMobile closeModal={() => setOpenNavBarModal(false)} />}
      <div className="w-full flex flex-row justify-end gap-5 font-poppins font-semibold xsm:hidden sm:hidden md:hidden">
        <div className="w-full flex flex-row justify-end gap-2 ">
          <button className="w-40 rounded text-md xmd:text-sm xmd:w-32 py-2 m-1 border border-gray300 text-gray700 bg-white">{t('navBarButton.onBoarding')}</button>
          <button className="w-32 rounded py-2 m-1 xmd:w-20 bg-darkblue700 text-white xmd:text-sm">{t('navBarButton.login')}</button>
        </div>
       {/* NASCONDO MOMENTANEMANETE IL CAMBIO LINGUA   <button className="w-12 justify-end flex items-center " onClick={() => setLanguageModalOpen(!languageModalOpen)}>
          <img src={currentLanguageIcon} alt="Current Language" />
          {languageModalOpen ? <img src={ChevronUp} alt="Chevron Up" /> : <img src={ChevronDown} alt="Chevron Down" />}
        </button> 
        {languageModalOpen && (
          <div className="absolute bg-white shadow-md mt-14 w-32 font-poppins  xmd:text-sm font-semibold xsm:hidden sm:hidden md:hidden">
            {(t("languages", { returnObjects: true }) as Choice[]).map((choice, index) => (
              <button className="flex gap-x-4 items-center px-4 py-2 w-full text-left  xmd:text-sm" key={index} onClick={() => changeLanguage(choice)}>
                <img src={choice.icon} alt="Language Icon" />
                <span>{choice.language}</span>
              </button>
            ))}
          </div>
        )}*/}
      </div>
    </div>
  );
};
