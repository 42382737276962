import React from 'react';
import './banner-style.css';

const items = [
  'PUBBLICITà ONLINE',
  'SITO WEB',
  'MENU DIGITALE',
];

export const Marquee = () => {
  return (
    <div className="marquee -rotate-3 xsm:-rotate-6 sm:-rotate-6  md:-rotate-6">
      <ul className="marquee__content">
        {Array.from({ length: 30 }).flatMap((_, index) =>
          items.map((item, itemIndex) => (
            <li key={`${index}-${itemIndex}`} className="marquee__item">
              &nbsp;{item}&nbsp;-
            </li>
          ))
        )}
      </ul>
    </div>
  );
};
