import React from "react";

interface ButtonProps {
  text: string;
  isColoured: boolean;
  link?: string;
  icon?: string;
  id?: string;
  extraClassName?: string;
}

export const Button = ({ text, isColoured, link, icon, id, extraClassName }: ButtonProps) => {
  return (
    <a href={link} className="text-primary700 text-md font-semibold mt-3">
      <button
        id={id}
        className={`xsm:w-full sm:w-full md:w-full px-[28px] py-[16px] flex flex-row justify-center font-poppins rounded relative z-10 ${
          isColoured ? "bg-primary500 text-white" : "border border-gray300 text-gray700 bg-white"
        } ${extraClassName || ""}`} 
      >
        {icon && (
          <div className="w-6 h-6 mx-3">
            <img src={icon} alt="icon" />
          </div>
        )}
        <span>{text}</span>
      </button>
    </a>
  );
};
