import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishLanguageIcon from "@images/english-language-icon.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";
i18n
  .use(initReactI18next)
  .init({
    resources: {
        en: {
          translation: {
            navBarMenuVoices: [
              {
                name: "Home"
              },
              {
                name: "Products"
              },
              {
                name: "Resources"
              },
              {
                name: "Prices"
              }
            ],
            changeLanguage: "Change language",
            languages: [
              {
                language: "Italian",
                lng: "it",
                icon: italianLanguageIcon
              },
              {
                language: "English",
                lng: "en",
                icon: englishLanguageIcon
              }
            ],
            navBarButton: {
              login: "Login",
              onBoarding: "Join the Club"
            },
            heroHeaderSectionTexts: {
              title: "Marketing for the restaurant industry",
              paragraphPart1: "The intuitive software that boosts your business. Strategic consulting included for efficient and independent management. Start now on your own.",
              privacyPart1: "We handle your data with care.",
              privacyPart2: "See the Privacy policy.",
              privacyLink: "Privacy policy",
              button: "Inizia ora",
              paragraphPart1mobile: "The intuitive software that boosts your business. Strategic consulting included. Start now on your own."
            },
            socialProofSection: {
              title: "Chosen by the best restaurants throughout Italy",
              titlemobile: "Chosen by the best restaurants"
            },
            socialProofSection2: {
              title: "See what they say"
            },
            featuresSectionTextsFirstPart: {
              title: "Optimize social media activity with artificial intelligence",
              paragraph: "Reach interested customers and optimize your online presence with smart and intuitive tools.",
              firstFeature: "Optimized Facebook and Instagram sponsored posts",
              secondFeature: "Captivating graphics for advertising",
              thirdFeature: "Create social posts with Artificial Intelligence",
              button: "Discover the details"
            },
            featuresSectionTextsSecondPart: {
              title: "Manage menus, reservations, and online orders from your website",
              paragraph: "From your website, your customers can book, order, and browse through your digital menu. Domain and Hosting included in subscription.",
              firstFeature: "Digital menu with QR Code",
              secondFeature: "Online reservations without commissions",
              thirdFeature: "Delivery, Takeaway, E-commerce without commissions",
              button: "Discover the details"
            },
            featuresSectionTextsThirdPart: {
              title: "Maximize the value of customer data",
              paragraph: "Enhance every interaction with your customers: leverage the power of data to offer personalized experiences.",
              firstFeature: "Customer details connected with website and newsletter system",
              secondFeature: "Import and export customer data in CSV files",
              thirdFeature: "Automatic emails for reminders, reviews, purchases, etc.",
              button: "Discover the details"
            },
            testimonialSectionTexts: {
              review: "“This platform is helping us increase our business by reaching more customers. We are very satisfied with Restaurants Club.”",
              reviewmobile: "“Restaurants Club is helping us increase our business by reaching more customers. We are very satisfied.”",
              reviewer: "Andy Perini",
              reviewerSpecification: "Owner of Andy Pizza, Pizzeria in the province of Rome"
            },
            reviewSectionTexts: {
              review: "“Social program, publications, reservations, and orders are all really well managed but above all having the possibility of an e-commerce and being able to input each customer into their own database are fundamental nowadays to grow our businesses.”",
              reviewer: "_Emanuele Caiazza",
              reviewerSpecification: "Owner of Primo Pasta, restaurant in Pisa"
            },
            socialSectionTexts: {
              title: "Reach customers interested in your place with sponsored posts on social media",
              titleMobile: "Reach interested people with sponsored posts",
              subtitle: "Our most loved feature!",
              paragraphPart: "Create effective ads in a few clicks: choose the user segment you want to engage, select or upload an image, choose the description and budget from optimized suggestions using artificial intelligence",
              paragraphPartMobile: "Effective ads in one click: image, text, and budget are handled 100% by AI"
            },
            percentageSectionTexts: {
              title: "Why focus on your online business today?",
              titleMobile: "Today: Focus online",
              subtitle: "Create valuable interactions with interested customers: online is where all relationships begin.",
              subtitleMobile: "Online is where all relationships begin with your customers",
              paragraphPart: {
                1: "of customers choose a restaurant based on social media",
                2: "of customers search online before booking",
                3: "of customers look for a personalized experience"
              },
              paragraphPartMobile: {
                1: "choose a restaurant based on social media",
                2: "search online before booking",
                3: "look for a personalized experience"
              },
              percentage: {
                1: "77%",
                2: "90%",
                3: "88%"
              }
            },
            pricing: {
              subtitle: "Our offer",
              title: "Clear prices and constant support:  Results equally efficient ",
              text: "A subscription without surprises. Try it for 30 days at half price.",
              info1: {
                title: "Unlimited strategic support on WhatsApp",
                text: "Direct contact with a dedicated expert on WhatsApp, to work together for the success of your place."
              },
              info2: {
                title: "Training and 1-1 consulting in video call",
                text: "For a personalized strategy or specific training, book your individual consultation in a video call."
              },
              info3: {
                title: "Access to hundreds of resources for marketing",
                text: "Discover and apply a wide selection of exclusive materials to create effective and engaging campaigns in your restaurant"
              },
              card: {
                pricing: "€220",
                text: "+ VAT per month",
                title: "Starting from",
                contact: "Contact us",
                subtitle: "PROMO: Credit for a sponsored post per month included.",
                info: {
                  title: "What's included?",
                  subtitle: "Monthly or annual plan. Always without commissions."
                }
              },
              iconlist: {
                text1: "Website with Online Menu with QR Code",
                text2: "Sponsored Facebook and Instagram posts",
                text3: "Social Calendar with hundreds of graphics",
                text4: "Artificial Intelligence for Marketing",
                text5: "Digital customer list and Mail marketing Automation",
                text6: "EXTRA: +€40/month for reservations from your website",
                text7: "EXTRA: +€40/month for delivery and takeaway from the website"
              },
              iconlistMobile: {
                text1: "Website with Online Menu",
                text2: "Sponsored Social posts",
                text3: "Social Calendar with graphics",
                text4: "AI for Marketing",
                text5: "Customer list and Mail Automation",
                text6: "EXTRA: +€40/month for reservations from your website",
                text7: "EXTRA: +€40/month for delivery and takeaway from the website"
              }
            },
            featuresTeam: {
              subtitle: "Who we are",
              title: "The Restaurants Club team",
              text: "We are a group of young entrepreneurs who want to enhance the world of restaurant industry. We are an innovative startup. We are developers, data scientists, marketing experts, trainers, and consultants.",
              textMobile: "We are young entrepreneurs who want to enhance the restaurant industry. We are an innovative startup.",
              info1: {
                title: "Our vision",
                text: "To democratize customer acquisition and loyalty technologies, making them accessible to all players in the sector.",
                textMobile: "To democratize customer acquisition and loyalty technologies."
              },
              info2: {
                title: "Our mission",
                text: "To support restaurant industry activities in communicating and spreading their unique interpretation of food and wine culture.",
                textMobile: "To support restaurant industry activities in communicating their unique interpretation of enogastronomic culture."
              }
            },
            freetrialCardTexts: {
              title: "Try it 30 days at half price.",
              subtitle: "INCLUDES: setup + first sponsored post + 4 strategic consultations",
              button: {
                1: "WhatsApp Chat",
                2: "Discover the details"
              }
            },
            faq: {
              subtitle: "Everything you need to know",
              title: "Frequently Asked Questions",
              question: {
                1: "Where are the offices of Restaurants Club?",
                2: "Is Restaurants Club a management software?",
                3: "Is Restaurants Club a delivery or online reservation app?",
                4: "Do I have to use the Restaurants Club software personally?",
                5: "What is the duration of the contractual commitment with Restaurants Club?",
                6: "Will I receive assistance and support from Restaurants Club?"
              },
              answer: {
                1: "We are in Milan, Pisa, and Naples. We operate throughout the Italian territory.",
                2: "No, Restaurants Club is Automated Marketing Software. We have integrations with various cash management systems to simplify your daily work.",
                3: "Also. Although the core business is the effective management of marketing, the software has a delivery and online booking add-on. Your customers can order takeaway, have the order delivered, or book a table directly from your site.",
                4: " Who are we to tell you what to do! The software is designed to make marketing management easier for you, so ideally the highest value appreciation is when using Restaurants Club yourself. However, if you think you can entrust this task to a trusted collaborator, it is absolutely possible. The important thing is to remain the administrator of your sensitive data.",
                5: "You can commit to Restaurants Club for a duration of 1 month or 1 year. If you choose the monthly option, you can renew the contract month by month on your own. You can indeed cancel your subscription at any time, we just ask for 15 days' notice. If you choose the annual option, we ask you to commit with us for at least 6 months. After this period, you can cancel your subscription. With the annual contract, you will benefit from a discount relative to the price of a single month.",
                6: "Constantly. Our customer care service will follow you step by step, from setting up the software to providing you with strategic advice on marketing and promotion. Then there are the specialized consultants, video courses, etc. In short, you are well-covered!"
              }
            },
            footer: {
              subtitle: "The artificial intelligence software to engage customers who love what you do",
              aitool: {
                title: "Free AI Tools",
                1: "Position Analysis",
                2: "Posts for your socials",
                3: "Typical Customer Analysis",
                4: "Examples of Offers",
                5: "Restaurant Ranking"
              },
              info: {
                title: "Info",
                1: "Privacy Policy",
                2: "Cookie Policy",
                3: "Ethical Code"
              },
              copyright: "© 2024 Restaurants Club is a SaaS by AiGot Srl. VAT ID: 11498080966 - Capital: €110,000. All rights reserved."
            },
            contact: {
              subtitle: "Contacts",
              title: "Let's have a chat?",
              text: "Our team is available for you.",
              info1: {
                title: "Write to the team!",
                subtitle: "Doubts or questions? We are here!",
                text: "info@restaurants.club"
              },
              info2: {
                title: "Chat on WhatsApp",
                subtitle: "Support available 24/7",
                text: "+39 334 105 3511"
              },
              info3: {
                title: "Come visit us",
                subtitle: "We look forward to seeing you at our office!",
                text: "Via Santa Maria, 25, 56126 Pisa PI"
              },
              info4: {
              title: "Call us",
              subtitle: "Mon-Fri from 9 am to 6 pm.",
              text: "+39 050 098 5159"
              }
            },
            hubspots: {
              subtitle: "Contact us",
              title: "Would you like to know more?",
              text: "We can't wait to answer your questions.",
              loading: "Loading ..."
            }
          }
        }  ,  
      it: {
        translation: {
          navBarMenuVoices: [
            {
              name: "Home",
            },
            {
              name: "Prodotti",
            },
            {
              name: "Risorse",
            },
            {
              name: "Prezzi",
            },
            {
              name: "Blog",
            }
          ],
          changeLanguage: "Cambia lingua",
          languages: [
            {
              language: "Italiano",
              lng: "it",
              icon: italianLanguageIcon
            },
            {
              language: "Inglese",
              lng: "en",
              icon: englishLanguageIcon
            }
          ],
          navBarButton: {
            login: "Accedi",
            onBoarding: "Entra nel Club"
          },
          heroHeaderSectionTexts: {
            title: "Porta facce nuove al tuo ristorante e falle innamorare",
            paragraphPart1: "Fai del tuo ristorante il luogo del cuore dei tuoi clienti ideali. Siamo il software facile con cui spargi la voce, fai colpo e conquisti",
            privacyPart1: "Trattiamo i tuoi dati con cura.",
            privacyPart2: "Vedi la",
            privacyLink: "Privacy policy",
            button: "Inizia ora",
            button2: "Scopri di più",
            paragraphPart1mobile: "Il software intuitivo che potenzia la tua attività. Consulenza strategica inclusa. Inizia ora in autonomia.",
          },
          socialProofSection: {
            title: "Ecco chi ci ha scelto:",
            titlemobile: "Ecco chi ci ha scelto:",
          },
          socialProofSection2: {
            title: "I nostri partner"
          },
          featuresSectionTextsFirstPart: {
            title: "Ottimizza l’attività sui social con l’intelligenza artificiale",
            paragraph: "Raggiungi clienti interessati e ottimizza la tua presenza online con strumenti intelligenti e intuitivi.",
            firstFeature: "Sponsorizzate su Facebook e Instagram ottimizzate",
            secondFeature: "Grafiche accattivanti per la pubblicità",
            thirdFeature: "Crea post social con l’Intelligenza Artificiale",
            button: "Scopri i dettagli"
          },
          featuresSectionTextsSecondPart: {
            title: "Gestisci menù, prenotazioni e ordini online dal tuo sito",
            paragraph: "Dal tuo sito web i tuoi clienti possono prenotare, ordinare e vedere il tuo menù digitale. Dominio e Hosting inclusi nell'abbonamento",
            firstFeature: "Menù digitale con QR Code",
            secondFeature: "Prenotazioni online senza commissioni",
            thirdFeature: "Delivery, Asporto, E-commerce senza commissioni",
            button: "Scopri i dettagli"
          },
          featuresSectionTextsThirdPart: {
            title: "Ottieni il massimo dai dati dei clienti",
            paragraph: "Valorizza ogni interazione con i tuoi clienti: sfrutta la potenza dei dati per offrire esperienze personalizzate.",
            firstFeature: "Agenda clienti connessa con sito e sistema per newsletter",
            secondFeature: "Importa ed esporta i dati dei clienti con file CSV",
            thirdFeature: "Mail automatiche per promemoria, recensioni, acquisti etc",
            button: "Scopri i dettagli"
          },
          testimonialSectionTexts: {
            review: "“Questa piattaforma ci sta aiutando ad incrementare il nostro lavoro raggiungendo più clienti. Siamo molto soddisfatti di Restaurants Club.”",
            reviewmobile: "“Ci sta aiutando ad incrementare il nostro lavoro raggiungendo più clienti. Siamo molto soddisfatti.”",
            reviewer: "Andy Perini",
            reviewerSpecification: "Titolare di Andy Pizza, Pizzeria in provincia di Roma"
          },
          reviewSectionTexts: {
            review: "“Programma social, pubblicazioni, prenotazioni ed ordini davvero ben curati ma più di tutto avere la possibilità di un eCommerce e di poter caricare ogni cliente nel proprio database sono nei nostri giorni fondamentali per far crescere le nostre attività.”",
            reviewer: "— Emanuele Caiazza",
            reviewerSpecification: "Titolare di Primo Pasta, ristorante a Pisa"
          },
          socialSectionTexts: {
            title: "Raggiungi clienti interessati al tuo locale con le sponsorizzate sui social",
            titleMobile: "Raggiungi interessati con le sponsorizzate",
            subtitle: "La nostra funzione più amata!",
            paragraphPart: "Crea pubblicità efficaci in pochi click: scegli il segmento di utenti che vuoi coinvolgere, seleziona o carica un’immagine, descrizione e budget sono già suggeriti per te con l’intelligenza artificiale",
            paragraphPartMobile: "Pubblicità efficaci in un click: immagine, testo e budget sono già pronti con l'AI"
           },
          percentageSectionTexts: {
            title: "vai online in 3 step",
            titleMobile: "vai online in 3 step",
            subtitle: "Crea interazioni di valore con clienti interessati: online è dove oggi tutte le relazioni possono iniziare.",
            subtitleMobile: "Online è dove oggi tutte le relazioni con i tuoi clienti possono iniziare",
            paragraphPart: {
              1: "Registrati per avere il tuo sito web e la tua dashboard",
              2: "Crea il tuo menù e il tuo sito e personalizza ogni dettaglio",
              3: "Fai partire la tua prima campagna pubblicitaria sui social",
            },
            paragraphPartMobile: {
              1: "Registrati per avere il tuo sito web e la tua dashboard",
              2: "Crea il tuo menù e personalizza il tuo sito",
              3: "Fai partire la tua prima campagna pubblicitaria sui social",
            },
        },
        pricing: {
          subtitle: "La nostra offerta",
          title: "Non ti lasciamo da solo",
          text: "Assistenza 24/24, strategia ad hoc e accademia. Ti abbiamo già detto che ci piacciono i software con dietro le persone?",

          info1: {
            title: "Ciccio ti dà supporto sempre",
            text: "È il nostro assistente virtuale, sempre pronto a risolvere i tuoi dubbi su WhatsApp. Scrivigli quando vuoi!",
          },
          info2: {
            title: "Una strategia apposta per te",
            text: "In un mare di consigli vaghi, noi ci concentriamo su piani su misura per te",
          },
          info3: {
            title: "Academy e corso on boarding",
            text: "Non vogliamo solo offrirti un software, vogliamo trasferirti tutte le lezioni di marketing che abbiamo appreso.",
          },
          card: {
            pricing: "€ 220 ",
            text: "+ IVA al mese",
            title: "Un solo piano, tutto incluso",
            subtitle: "Credito per una sponsorizzata al mese incluso.",
            contact: "Inizia Ora",
            info: {
              title: "Cosa include?",
              subtitle: "Piano mensile o annuale. Sempre senza commissioni.",
            },
          },
          iconlist: {
            text1: "Sito web con Menù Online con QR Code",
            text2: "Pubblicità su Facebook e Instagram",
            text3: "Calendario Social con grafiche personalizzabili",
            text4: "Consulenza marketing dedicata",
            text5: "Academy",
    
          },
          iconlistMobile: {
            text1: "Sito web con Menù Online con QR Code",
            text2: "Pubblicità su Facebook e Instagram",
            text3: "Calendario Social con grafiche personalizzabili",
            text4: "Consulenza marketing dedicata",
            text5: "Academy",
          },
        },
        featuresTeam: {
          title: "Viva i software con dietro le persone!",
          text: "Alle redini del nostro software ci sono Francesca, Pablo, Marco, e molti altri. Se hai bisogno di aiuto, uno dei nostri è pronto a seguirti passo passo. Anche di notte con l'assistenza via Whatsapp.",
          textMobile: "Alle redini del nostro software ci sono Francesca, Pablo, Marco, e molti altri. Se hai bisogno di aiuto, uno dei nostri è pronto a seguirti passo passo. Anche di notte con l'assistenza via Whatsapp.",
          info1: {
            title: "La nostra visione",
            text: "Democratizzare le tecnologie di acquisizione e fidelizzazione dei clienti, rendendole accessibili a tutti gli attori nel settore.",  
            textMobile: "Democratizzare le tecnologie di acquisizione e fidelizzazione dei clienti.",
          },
          info2: {
            title: "La nostra missione",
            text: "Supportare le attività di ristorazione nel comunicare e diffondere la loro interpretazione unica della cultura enogastronomica. ",
            textMobile: "Supportare le attività di ristorazione nel comunicare la loro interpretazione unica della cultura enogastronomica. ",
          }
        },
        freetrialCardTexts: {
          title: "Un'agenzia marketing virtuale tutta tua",
          subtitle: "Con Restaurants Club hai tutto sotto controllo. Puoi aggiornare il tuo sito all'istante, creare contenuti social straordinari e lanciare campagne in pochi click! Scopri di più su come fare marketing in modo veloce e perfetto.",
          button: {
            1: "Prenota una demo",
          }
        },
        faq: {
          subtitle: "Tutto quello che devi sapere",
          title: "Domande Frequenti",
          question: {
            1: "Dove sono gli uffici di Restaurants Club?",
            2: "Restaurants Club è un gestionale?",
            3: "Restaurants Club è un App di delivery o prenotazioni online?",
            4: "Devo utilizzare personalmente il software Restaurants Club?",
            5: "Qual è la durata dell'impegno contrattuale con Restaurants Club?",
            6: "Riceverò assistenza e supporto da Restaurants Club?",
          },
          answer: {
            1: "Siamo a Milano, Pisa e Napoli. Operiamo su tutto il territorio italiano. ",
            2: "No, Restaurants Club è un software di Marketing Automatizzato. Abbiamo integrazioni con diversi gestionali di cassa per semplificare il tuo lavoro quotidiano.",
            3: "Anche. Sebbene il core business sia la gestione efficace del marketing, il software dispone di un add-on di delivery e prenotazione online. I tuoi clienti possono ordinare da asporto, farsi recapitare l’ordine o prenotare un tavolo direttamente dal tuo sito. ",
            4: "Chi siamo noi per dirti cosa fare? Il software è pensato per renderti la gestione del marketing più semplice per cui sarebbe l’ideale se apprezzassi tu stesso l’usabilità di Restaurants Club. Tuttavia, se pensi di poter affidare questo compito a un tuo collaboratore fidato, nulla in contrario. L’importante è rimanere l’amministratore dei tuoi dati sensibili. ",
            5: "Puoi impegnarti con Restaurants Club per la durata di 1 mese o di 1 anno. Se scegli il mensile, puoi rinnovare il contratto di mese in mese in totale autonomia. Puoi infatti annullare il tuo abbonamento in qualsiasi momento, ti chiediamo solo un preavviso di 15 giorni. Se scegli l’annuale, ti chiediamo di impegnarti con noi per almeno 6 mesi. Dopo questo periodo puoi annullare il tuo abbonamento. Con il contratto annuale beneficierai di uno sconto relativo rispetto al prezzo del singolo mese.",
            6: "Costantemente. Il nostro servizio di customer care ti seguirà passo passo, dal setting del software fino a fornirti consigli strategici in materia di marketing e promozione. Poi ci sono i consulenti specializzati, i video corsi, ecc. Insomma, hai le spalle coperte!",
          }
        },
        footer: {
          subtitle: "Il software di intelligenza artificiale per coinvolgere clienti che amano ciò che fai",
          aitool: {
            title: "Strumenti AI Gratis",
            1: "Analisi Posizione",
            2: "Post per i tuoi social",
            3: "Analisi Cliente Tipo",
            4: "Esempi di Offerte ",
            5: "Classifica Ristoranti",
          },
          info: {
            title: "Info",
            1: "Privacy Policy",
            2: "Cookie Policy",
            3: "Codice Etico",
            4: "Termini e Condizioni",
          },
          copyright: "© 2024 Restaurants Club è un SaaS di AiGot Srl. P. IVA: 11498080966 - Capitale Sociale: 110.000€. Tutti i diritti riservati.",
        },
        contact: {
          subtitle: "Metti alla prova Ciccio, ora!",
          title: "Metti alla prova Ciccio, ora!",
          text: "Anche Ciccio adora le strategie su misura. Infatti, ha ideato dei percorsi interattivi apposta per te.",
          info1: {
            title: "8 Idee da pubblicare sui Social",
            text: "Provalo su WhatsApp",
          },
          info2: {
            title: "Analisi posizione locale",
            text: "Provalo su WhatsApp",
          },
          info3: {
            title: "Conosci davvero i tuoi clienti?",
            text: "Provalo su WhatsApp",
          },
          info4: {
            title: "Consigli per una tua sponsorizzata",
            text: "Provalo su WhatsApp",
          },

        },
           buyersTexts: {
            title: "siamo perfetti per te se...",
            titleMobile: "siamo perfetti per te se...",
            paragraphPart: {
              1: "Sei stufo delle agenzie",
              2: "Non hai tempo da perdere",
              3: "Meriti di meglio"
            },
            subtitle: {
              1: "Vuoi mettere le mani in pasta e far vedere a tutti quanto sei bravo far risplendere il tuo ristorante sui tuoi canali.",
              2: "Sai bene quant'è importante fare marketing. È per questo che vuoi farlo senza togliere tempo al tuo lavoro..",
              3: "Nel tuo ristorante ci hai messo il cuore. Ora aspiri a farlo diventare un brand o desideri aprirne di nuovi."
            }
          },

        hubspots: {
          title: "se sei arrivato fino a qui, ti abbiamo incuriosito ;)",
          text: "Allora, vale proprio la pena conoscerci! Se sei un po' interessato (basta poco) lascia qui i tuoi dati, ti contatteremo presto.",
          loading: "Caricamento ..."
        },

      },
    },
  },
    lng: 'it',
    fallbackLng: 'en',
    interpolation: {
    escapeValue: false,
  },
  });
export default i18n;
