import React from "react";

interface Feature {
  icon: string;
  text1: string;
  text2: string;
  link: string;
  text3: string;
  id: string;
}
interface ContactOptionsCardProps {
  features: Feature[];
}
export const ContactOptionsCard: React.FC<ContactOptionsCardProps> = ({ features }) => (
  <div className="font-poppins grid mx-auto justify-center items-center grid-cols-4 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xmd:grid-cols-2 gap-4 xmd:w-[600px]">
    {features.map((feature, index) => (
      <div key={index} className="bg-gray50 h-72 w-72 flex flex-col justify-between p-6 mx-auto">
        <div>
          <img src={feature.icon} alt="icon" />
        </div>
        <div className="flex flex-col">
          <p className="text-gray900 font-poppins text-xl font-semibold">{feature.text1}</p>
        </div>
        <a id={feature.id} href={feature.link} className="text-gray700 px-4 py-2.5 border-2 rounded font-poppins text-md font-semibold bg-white w-fit">
          {feature.text3}
        </a>
      </div>
    ))}
  </div>
);
