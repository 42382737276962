import React from "react";
import { useTranslation } from "react-i18next";

interface TextBannerProps {
  subtitle?: string;
  title: string;
  paragraph: string;
  paragraphMobile?: string;
}

export const TextBanner = ({ subtitle, title, paragraph, paragraphMobile }: TextBannerProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full mb-10 max-w-3xl xsm:w-[80%] sm:w-[80%] xsm:m-auto sm:m-auto xsm:mb-10 sm:mb-10">
      <h3 className="font-brevia text-display-md xsm:text-display-sm sm:text-display-sm md:text-display-sm font-semibold text-gray900 mt-6 uppercase">
        {title}
      </h3>
      <p className="font-poppins text-xl font-regular text-gray600 mt-2  ">
        {paragraph}
      </p>

    </div>
  );
};
