import React from "react";

interface Feature {
  icon: string;
  text: string;
  textMobile?: string;
}

interface IconList2Props {
  features: Feature[];
}

export const IconList2 = ({ features }: IconList2Props) => {
  return (
    <div>
      <div className="w-full flex flex-col gap-y-6 px-2 mt-6 font-poppins text-md font-regular text-gray-600">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-row items-start gap-3">
            <div className="flex-none">
              <img src={feature.icon} alt="icon" className="w-8 h-8" />
            </div>
            <div>
              <p className="xsm:hidden sm:hidden" >{feature.text}</p>
              <p className="md:hidden xmd:hidden lg:hidden xl:hidden 2xl:hidden" >{feature.textMobile}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
