import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../reusable/button";
import { IconList2 } from "../reusable/icon-list2";
import iconCheck from "@images/check-circle.svg";
import iconRocket from "@images/rocket-icon.png";

interface ToggleButtonProps {
  selected: string;
  onSelect: (plan: string) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ selected, onSelect }) => {
  return (
    <div className="w-fit font-poppins rounded-lg p-1 flex space-x-4 mb-4 bg-gray-100 mx-auto">
      <button
        onClick={() => onSelect('annual')}
        className={`py-2 px-4 ${selected === 'annual' ? 'bg-white border rounded-lg shadow-md text-gray-700' : 'bg-gray-100'} text-gray-500 font-semibold`}>
        Annuale
      </button>
      <button
        onClick={() => onSelect('monthly')}
        className={`py-2 px-4 ${selected === 'monthly' ? 'bg-white border rounded-lg shadow-md text-gray-700' : 'bg-gray-100'} text-gray-500 font-semibold`}>
        Mensile
      </button>

    </div>
  );
};

export const PricingCard: React.FC = () => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState('annual');
  const price = selectedPlan === 'annual' ? 220 : 250;

  return (
    <div className="xmd:w-[85%] lg:w-full xl:w-full 2xl:w-[85%] mx-auto">
      <ToggleButton selected={selectedPlan} onSelect={setSelectedPlan} />
      <div className="rounded-2xl border">
        <div className="text-md font-semibold gap-8 lg:gap-0 m-4 lg:m-2.5 xl:m-3 flex flex-row sm:flex-col xsm:flex-col md:flex-col">
          <div className="w-[94%] text-md font-semibold flex flex-col">
            <p className="font-brevia text-display-xs font-semibold text-gray900 uppercase">
              {t("pricing.card.title")}
            </p>
            <div className="font-brevia w-full text-md font-semibold flex flex-row gap-5">
              <div>
                <img src={iconRocket} alt="Icon" />
              </div>
              <p className="font-poppins text-md font-regular text-gray600 w-full">
                {t("pricing.card.subtitle")}
              </p>
            </div>
          </div>
          <div className="w-full text-md font-semibold flex flex-row items-baseline gap-2">
            <h3 className="font-brevia text-display-xl font-semibold text-gray900 uppercase">
              € {price}
            </h3>
            <p className="font-poppins text-md font-medium text-gray600">
              {t("pricing.card.text")}
            </p>
          </div>
        </div>
        <div className="w-full mt-8 border-y">
          <div className="mx-5 my-10">
            <p className="font-poppins text-md font-semibold text-gray900 mt-2">
              {t("pricing.card.info.title")}
            </p>
            <p className="font-poppins text-md font-regular text-gray600 mt-2">
              {t("pricing.card.info.subtitle")}
            </p>
            <IconList2
              features={[
                { icon: iconCheck, text: t("pricing.iconlist.text1"), textMobile: t("pricing.iconlistMobile.text1") },
                { icon: iconCheck, text: t("pricing.iconlist.text2"), textMobile: t("pricing.iconlistMobile.text2") },
                { icon: iconCheck, text: t("pricing.iconlist.text3"), textMobile: t("pricing.iconlistMobile.text3") },
                { icon: iconCheck, text: t("pricing.iconlist.text4"), textMobile: t("pricing.iconlistMobile.text4") },
                { icon: iconCheck, text: t("pricing.iconlist.text5"), textMobile: t("pricing.iconlistMobile.text5") },
              ]}
            />
          </div>
        </div>
        <div className="text-md font-semibold m-6 mb-10 w-full">
          <Button id="pricing-card-button" text={t("pricing.card.contact")} isColoured={true} link="#hubspotForm" extraClassName="w-full" />
        </div>
      </div>
    </div>
  );
};
