import React from "react";
import { useTranslation } from "react-i18next";
import { TextBanner } from "../reusable/text-banner";
import CallCenterGirl from "@images/call-center-girl.png";
import { Button } from "../reusable/button";
import Fiko from "@images/fiko.png";
import Hand from "@images/sticker-hand.png";
import Question from "@images/question-stickers.png";

export const TeamFeatures = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-gray50 text-white rounded-2xl py-6 px-6 w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto relative  my-10 xsm:mt-40 sm:mt-40 md:mt-40">
        <div className="absolute inset-0 flex z-10">
          <div className=" relative w-full h-full">
            <img src={Hand} className="absolute -top-14 -left-14 md:w-[100px] md:-left-7 xsm:w-[100px] xsm:-left-7 sm:w-[100px] sm:-left-7" />
          </div>
        </div>
        <div className="absolute inset-0 flex justify-end">
          <div className="relative w-full h-full">
            <img src={Fiko} className="absolute top-0 z-20 xsm:top-[660px] sm:top-[660px] md:top-[550px] -right-28 md:w-48 xsm:w-40 sm:w-40 xsm:-right-8 sm:-right-8  md:-right-8" />
          </div>
        </div>
        <div className="relative z-10 flex flex-col gap-2 xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row ">
          <div className="relative w-[55%] xsm:w-full sm:w-full md:w-full xmd:w-[65%] xl:w-[65%] lg:w-[69%] flex flex-col px-8 xmd:px-2">
            <div className="xmd:p-5 lg:p-8 xl:p-12 2xl:p-12">
              <TextBanner
                title={t("featuresTeam.title")}
                paragraph={t("featuresTeam.text")}
                paragraphMobile={t("featuresTeam.textMobile")}
              />
              <div className="relative z-10">
                <Button id="heroButton" text={t("heroHeaderSectionTexts.button")} isColoured={true} link="#hubspotForm" />
              </div>
            </div>
          </div>
          <div className="relative w-[40%] xsm:w-full sm:w-full md:w-full xmd:absolute lg:absolute xl:absolute xmd:w-[45%] xl:w-[45%] mx-auto flex items-end xmd:bottom-0 xmd:right-0 xmd:z-10 lg:bottom-0 lg:right-0 lg:z-10 xl:bottom-0 xl:right-0 xl:z-10">
            <img src={CallCenterGirl} alt="Marco's Photo" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="flex justify-end mx-auto my-2 md:w-[90%] xmd:w-full lg:w-[85%] xl:w-[85%] 2xl:w-[85%]">
        <img src={Question} className="xsm:w-[60%] sm:w-[60%] md:w-[50%]" />
      </div>
    </>
  );
};
