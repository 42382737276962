import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../reusable/button";
import ChevronDown from "@images/chevron-down.svg";
import ChevronUp from "@images/chevron-up.svg";

type Voice = {
  name: string;
};

type Choice = {
  lng: string;
  icon: string;
  language: string;
};

type NavBarModalMobileProps = {
  closeModal: () => void;
};

export const NavBarModalMobile = ({ closeModal }: NavBarModalMobileProps) => {
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div ref={modalRef} className=" w-80 absolute right-2 top-[88px] bg-white text-gray900 text-md font-semibold font-poppins shadow-md">
      {/* <div className="flex flex-col gap-y-8 border-b border-gray300 py-8">
        {(t("navBarMenuVoices", { returnObjects: true }) as Voice[]).map((voice, index) => (
          <button className="text-left ml-3" key={index}>
            <span>{voice.name}</span>
          </button>
        ))}
      </div>
       NASCONDO MOMENTANEMANETE IL CAMBIO LINGUA 
      <div className="mx-3">
        <button className="w-full py-4 flex justify-between" onClick={() => setLanguageModalOpen(!languageModalOpen)}>
          <span>{t('changeLanguage')}</span>
          {languageModalOpen ? <img src={ChevronUp} alt="Chevron Up" /> : <img src={ChevronDown} alt="Chevron Down" />}
        </button>
      </div>
      {languageModalOpen && (
        <div className="flex flex-col items-start mx-3 gap-y-5 py-2">
          {(t("languages", { returnObjects: true }) as Choice[]).map((choice, index) => (
            <button className="flex gap-x-4 items-center" key={index} onClick={() => changeLanguage(choice.lng)}>
              <img src={choice.icon} alt="Language Icon" />
              <span>{choice.language}</span>
            </button>
          ))}
        </div>
      )}*/}
      <div className="mx-3 flex flex-col gap-y-3 py-5">
        <Button text={t('navBarButton.login')} isColoured={true} link="/login" />
        <Button text={t('navBarButton.onBoarding')} isColoured={false} link="/onboarding" />
      </div>
    </div>
  );
};
