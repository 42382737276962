import React, { useEffect } from 'react';

declare global {
  interface Window {
    dataLayer: any[];
    gtag?: (...args: any[]) => void;
  }
}

export const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(), 
      event: 'gtm.js'
    });
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5SSMMX6';
    scriptTag.onload = () => {
      if (typeof window.gtag === 'function') {
        window.gtag('js', new Date());
        window.gtag('config', 'GTM-5SSMMX6');
      }
    };
    document.head.appendChild(scriptTag);
  }, []);
  return null;
};
function gtag(...args: any[]) {
  window.dataLayer.push(args);
}
