import lostravizioLogo from "@images/stravizio-logo.png"
import borgoLogo from "@images/borgo-logo.png";
import ilcanguroLogo from "@images/ilcanguro-logo.png";
import osteriaLogo from "@images/osteria-logo.png";
import primoLogo from "@images/primo-logo.png";
import sumup from "@images/sumup.png";
import ponyu from "@images/ponyu.png";
import satispay from "@images/satispay.png";
import metaTech from "@images/meta-tech.png";
import googlePartner from "@images/google.png";
import metaBusiness from "@images/business.png";

export const logos = [
  {
    logo: borgoLogo
  },
  {
    logo: osteriaLogo
  },
  {
    logo: primoLogo
  },
  {
    logo: lostravizioLogo
  },
  {
    logo: ilcanguroLogo
  }
];

export const logos2 = [
  {
    logo: googlePartner
  },
  {
    logo: metaBusiness
  },
  {
    logo: metaTech
  },
  {
    logo: sumup
  },
  {
    logo: ponyu
  },
  {
    logo: satispay
  }
];
