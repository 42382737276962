import React from "react";
import { TextBanner } from "../reusable/text-banner";
import { useTranslation } from "react-i18next";
import HubSpotForm from "react-hubspot-form";
import Waves from "@images/waves.png";

export const Form = () => {
  const { t } = useTranslation();

  const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
  const formId = process.env.REACT_APP_HUBSPOT_FORM_ID;

  return (
    <div id="hubspotForm" className="flex flex-col xsm:w-[90%] sm:w-[90%] md:w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto">
      <div className="absolute w-full left-0">
        <img src={Waves} className="my-10" />
      </div>
      <div id="formComponent" className="flex flex-col gap-11 lg:flex-row xl:flex-row 2xl:flex-row mt-20 ">
        <div className="flex w-[90%] xsm:w-full sm:w-full md:w-full mt-10 pr-20  xsm:pr-0  sm:pr-0 md:pr-0 mx-auto">
          <TextBanner
            title={t("hubspots.title")}
            paragraph={t("hubspots.text")}
          />
        </div>
        <div className="flex justify-center w-[90%]  xsm:w-full sm:w-full md:w-full p-8">
          <div className="w-full">
            <HubSpotForm
              portalId={portalId}
              formId={formId}
              loading={<div>{t("hubspots.loading")}</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
