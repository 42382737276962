import * as LDClient from 'launchdarkly-js-client-sdk';

const clientSideID = '6679398637c74b107bcdb255';

const user = {
  key: 'sdk-56c8d892-0c9c-428b-8236-78e04234962f',
  anonymous: true
};

const ldClient = LDClient.initialize(clientSideID, user);

export default ldClient;
