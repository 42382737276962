import React, { useEffect, useState } from 'react';
import ldClient from './components/launch-darkly-client';
import { HeroHeader } from './components/hero-header-section/hero-header';
import { NavBar } from './components/navbar/navbar';
import { SocialProof } from './components/social-proof-section/social-proof';
import { Buyers } from './components/percentage-section/buyer-personas-section';
import { Steps } from './components/percentage-section/steps';
import { FreeTrial } from './components/free-trial-card/free-trial-card';
import { TeamFeatures } from './components/features-section/features-team';
import { Pricing } from "./components/pricing-section/pricing";
import { Form } from "./components/hubspot-form/hubspot-from";
import { ContactOptions } from './components/contact-options/contact-options';
import { FreeTrialBanner } from './components/free-trial-card/free-trial-banner';
import { Footer } from './components/footer/footer';
import { SocialProof2 } from './components/social-proof-section/social-proof-2';
import { CookieBanner } from './components/cookie-bar/cookie-banner';
import { GoogleTagManager } from './components/google-tag-manager';
import { Marquee } from './components/banner-scroll/banner-scroll';

export const App: React.FC = () => {
  const [flags, setFlags] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    ldClient.on('ready', () => {
      setFlags(ldClient.allFlags());
    });

    ldClient.on('change', (updatedFlags: any) => {
      setFlags(ldClient.allFlags());
    });

    return () => {
      ldClient.close();
    };
  }, []);

  return (
    <>
      <GoogleTagManager />
      <CookieBanner />
      <div className='xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto'>
        <NavBar />
        <HeroHeader />
      </div>
      <div className='w-full xsm:mt-0 sm:mt-0 md:mt-0 xmd:-mt-16 lg:-mt-2 -mt-20 left-3'>
        <Marquee />
      </div>
      <div className='xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto'>
        {flags['socialproof_homepage'] && <SocialProof />}
        {flags['benefit_principale_homepage'] && <FreeTrial />}
        {flags['buyer_personas_homepage'] && <Buyers />}
        {flags['step_registrazione_homepage'] && <Steps />}
        {flags['consulenza_homepage'] && <TeamFeatures />}
        {flags['pricing_homepage'] && <Pricing />}
        {flags['form_contatto_homepage'] && <Form />}
        {flags['contact_homepage'] && <ContactOptions />}
        {flags['partners_homepage'] && <SocialProof2 />}
      </div>
      <div className='w-full'>
        <FreeTrialBanner />
        <Footer />
      </div>
    </>
  );
};
