import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../reusable/button";
import iconChats from "@images/chats-icon.png";

export const FreeTrialBanner = () => {
  const { t } = useTranslation();
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <div className="bg-darkblue700 py-10 px-4">
      <div className="border-b border-gray600  pb-6 flex flex-col xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-x-20 xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] justify-center items-center mx-auto">
        <div className="w-full">
          <h3 className="font-brevia text-display-xs font-semibold text-white uppercase">
            {t("freetrialCardTexts.title")}
          </h3>
          <p className="font-poppins text-md font-regular text-white mt-2">
            {t("freetrialCardTexts.subtitle")}
          </p>
        </div>
        <div className="w-full mt-8 md:my-3 lg:my-3  xl:my-3  2xl:my-3 flex justify-center xmd:justify-end lg:justify-end xl:justify-end 2xl:justify-end">
          <div className="text-md font-semibold flex flex-col gap-4 w-[60%]">
            <Button id="freeTrialButton" icon={iconChats} text={t("freetrialCardTexts.button.1")} isColoured={false} link="#hubspotForm" />
          </div>
        </div>
      </div>
    </div>
  );
};
