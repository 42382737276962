import React from "react";
import { logos2 } from "./logos-routes";
import { useTranslation } from "react-i18next";

export const SocialProof2 = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full py-20 flex flex-col items-center">
      <h2 className="text-display-sm font-semibold text-darkblue700 font-brevia text-center uppercase">
        {t("socialProofSection2.title")}
      </h2>
      <div className="w-full flex flex-wrap gap-x-20 xmd:gap-x-20 lg:gap-x-20 gap-y-3 justify-center mt-10  lg:flex-row xl:flex-row 2xl:flex-row items-center">
        {logos2.map((logo, index) => (
          <div key={index} className="w-[30%] xmd:w-[20%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] flex justify-center">
            <img src={logo.logo} alt="logo" />
          </div>
        ))}
      </div>
    </div>
  );
};
