import React from "react";
import { useTranslation } from "react-i18next";
import arrowRight from "@images/arrow-right.svg";
import Circlemissionicon from "@images/circle-mission-icon.png";

export const Buyers = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full my-14 xsm:my-40 sm:my-40 md:my-40 xmd:my-20 text-center xmd:p-10 lg:p-10 xl:p-10 2xl:p-10 xsm:w-[90%] sm:w-[90%] md:w-[75%] xmd:w-full lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto">
      <div className=" my-10">
        <h3 className="xsm:hidden sm:hidden font-brevia text-display-md font-semibold text-gray900 mt-6 uppercase">
          {t("buyersTexts.title")}
        </h3>
        <h3 className="md:hidden xmd:hidden lg:hidden xl:hidden 2xl:hidden font-brevia text-display-sm font-semibold text-gray900 mt-6 uppercase">
          {t("buyersTexts.titleMobile")}
        </h3>
      </div>
      <div className="flex flex-col xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-center xmd:p-10 lg:p-10 xl:p-10 2xl:p-10 gap-10">
        <div className="">
          <p className="font-poppins text-xl font-semibold text-gray900 mt-2">
            {t("buyersTexts.paragraphPart.1")}
          </p>
          <p className="font-poppins text-md font-regular text-gray600 mt-2">
            {t("buyersTexts.subtitle.1")}
          </p>
          <a href="#hubspotForm" className="mt-4 mx-auto text-primary700 font-poppins font-medium flex items-center justify-center">
            Contattaci
            <img src={arrowRight} alt="Arrow Right" className="ml-2" />
          </a>
        </div>
        <div className="">
          <p className="font-poppins text-xl font-semibold text-gray900 mt-2">
            {t("buyersTexts.paragraphPart.2")}
          </p>
          <p className=" font-poppins text-md font-regular text-gray600 mt-2">
            {t("buyersTexts.subtitle.2")}
          </p>
          <a href="#hubspotForm" className="mt-4 mx-auto text-primary700 font-poppins font-medium flex items-center justify-center">
            Contattaci
            <img src={arrowRight} alt="Arrow Right" className="ml-2" />
          </a>
        </div>
        <div className="">
          <p className="font-poppins text-xl font-semibold text-gray900 mt-2">
            {t("buyersTexts.paragraphPart.3")}
          </p>
          <p className="font-poppins text-md font-regular text-gray600 mt-2">
            {t("buyersTexts.subtitle.3")}
          </p>
          <a href="#hubspotForm" className="mt-4 mx-auto text-primary700 font-poppins font-medium items-center flex justify-center">
            Contattaci
            <img src={arrowRight} alt="Arrow Right" className="ml-2" />
          </a>
        </div>
      </div>
    </div>
  );
};
